import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress, MenuItem, TextField, Tooltip } from "@mui/material";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";
import {
  useGetCostCenterUsersQuery,
  useGetUsersByOrgAndStatusQuery,
} from "state/rtkQuery/user/userQueries";
import {
  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW,
  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW,
} from "utils/constants";

import ChangeCostCenterModal from "./ChangeCostCenterModal";
import successGIF from "./cost-center-success.gif";

import AvatarRandomColor from "../ReusableDataGrid/AvatarRandomColor";
import ReusableActions from "../ReusableDataGrid/ReusableActions";
import ReusableDataGrid from "../ReusableDataGrid/ReusableDataGrid";

const JoinedTab = ({ orgName }) => {
  const [columns, setColumns] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { orgId } = useParams();
  const [orgPermissions, setOrgPermissions] = useState();
  const [usersCostCenters, setUsersCostCenters] = useState(null);
  const [emailTruncateLimit, setEmailTruncateLimit] = useState(
    EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCostCenter, setNewCostCenter] = useState({ id: "", name: "" });
  const [oldCostCenter, setOldCostCenter] = useState({ id: "", name: "" });
  const [open, setOpen] = useState(false);

  const permissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    if (orgId && permissions) {
      setOrgPermissions(
        permissions?.filter((permission) => permission.orgId === orgId)
      );
    }
  }, [orgId, permissions]);

  useEffect(() => {
    if (orgPermissions && orgPermissions?.[0]?.userType === "Approver") {
      setEmailTruncateLimit(
        EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW
      );
    }
  }, [orgPermissions]);

  // const mockCostCenters = [
  //   {
  //     _id: { $oid: "66c7a36c4e72d69ee277d428" },
  //     name: "Deloitte US Consulting",
  //     ref_id: "bf098c55-e14a-48a8-b14f-210045b4a3e0",
  //     platform_id: "63d834104dbcaf7680f1c052",
  //     created_date: "2024-08-22 20:45:32",
  //     updated_date: "2024-08-22 20:45:32",
  //   },
  //   {
  //     _id: { $oid: "66c7a36c4e72d69ee277d429" },
  //     name: "Deloitte Africa",
  //     ref_id: "179f5311-71a6-42f7-8519-04ae4b34dea0",
  //     platform_id: "63d834104dbcaf7680f1c052",
  //     created_date: "2024-08-22 20:45:32",
  //     updated_date: "2024-08-22 17:04:12",
  //   },
  //   {
  //     _id: { $oid: "66c7a36d4e72d69ee277d42a" },
  //     name: "Deloitte Japan",
  //     ref_id: "80508992-2fcf-4235-8cc6-2f9404c71a9a",
  //     platform_id: "63d834104dbcaf7680f1c052",
  //     created_date: "2024-08-22 20:45:33",
  //     updated_date: "2024-08-22 17:04:14",
  //   },
  //   {
  //     _id: { $oid: "66c7a36d4e72d69ee277d42b" },
  //     name: "Deloitte Brazil",
  //     ref_id: "56843d8b-f98b-4acd-bc21-4eaae6a32107",
  //     platform_id: "63d834104dbcaf7680f1c052",
  //     created_date: "2024-08-22 20:45:33",
  //     updated_date: "2024-08-22 17:04:15",
  //   },
  //   {
  //     _id: { $oid: "66c7a36d4e72d69ee277d42c" },
  //     name: "Deloitte Canada/Chile",
  //     ref_id: "1d74190f-5120-4db9-b243-1319f6d3be92",
  //     platform_id: "63d834104dbcaf7680f1c052",
  //     created_date: "2024-08-22 20:45:33",
  //     updated_date: "2024-08-22 20:45:33",
  //   },
  // ];

  const {
    data: platformData,
    isFetching: isPlatformDataLoading,
    isError: isPlatformDataError,
  } = useGetPlatformsQuery();

  // RTK Query to get Cost Center List

  const {
    data: costCenterListData,
    isFetching: isCostCenterListDataLoading,
    isSuccess: isCostCenterListDataSuccess,
    isError: isCostCenterListDataError,
  } = useGetCostCenterUsersQuery(
    {
      platformId: platformData?._id,
    },
    { skip: !platformData?._id }
  );

  //RTK Query to get users by org and status
  const {
    data: joinedUserListData,
    isFetching: isJoinedUserListDataLoading,
    isSuccess: isJoinedUserListDataSuccess,
    isError: isJoinedUserListDataError,
  } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Joined",
      platformId: platformData?._id,
      page: currentPage,
      perPage: 25,
      email: query === "" ? undefined : query,
    },
    { skip: !platformData?._id }
  );

  // Set datagrid rows
  // Combines both joined and remove requested users into a single list
  useEffect(() => {
    if (isJoinedUserListDataSuccess && joinedUserListData?.users) {
      // User cost centers need to be set in a separate state to allow for dynamic updates on the table by org owners and approvers
      joinedUserListData?.users?.map((user) => {
        setUsersCostCenters((prev) => ({
          ...prev,
          [user.id]: user?.costCenter?.length > 0 ? user.costCenter : "",
        }));
      });
      setUpdatedRows(joinedUserListData?.users);
    }
  }, [joinedUserListData, isJoinedUserListDataSuccess]);

  // Mutation to update user status
  const [
    updateUserOrgStatus,
    {
      isLoading: isUpdateUserOrgStatusLoading,
      isError: isUpdateUserOrgStatusError,
    },
  ] = useUpdateUserOrgStatusMutation();

  // Logged in user's email
  const userEmail = useSelector((state) => state.auth.userAccount?.username);

  // Function to execute when button is clicked in action column of datagrid
  const action = (userId, platformId, orgId, userEmail) => {
    return (rejectReason, otherReason) => {
      updateUserOrgStatus({
        userId: userId,
        body: {
          platforms: {
            [platformId]: {
              organizations: {
                [orgId]: {
                  membership_status: "Remove-Requested",
                  reason: rejectReason === "Other" ? otherReason : rejectReason,
                  changed_by: userEmail.toLowerCase(),
                },
              },
            },
          },
        },
        attribute: "membership_status",
      });
    };
  };

  // Function to execute when cost center dropdown is changed
  const updateCostCenter = (userId, platformId, costCenterID) => {
    const loadingToast = toast.loading("Updating cost center for user", {
      style: {
        minWidth: "300px",
      },
    });

    updateUserOrgStatus({
      userId: userId,
      body: {
        platforms: {
          [platformId]: {
            cost_center_id: costCenterID,
          },
        },
      },
      attribute: "cost_center_id",
    })
      .unwrap()
      .then(() => {
        toast.dismiss(loadingToast);
        toast(
          <div>
            <img
              src={successGIF}
              height={"40px"}
              width={"40px"}
              alt="Success Icon"
            />
            Cost center updated successfully!
          </div>,
          {
            duration: 3500,
            style: {
              minWidth: "fit-content",
            },
          }
        );
      })
      .catch((error) => {
        toast.dismiss(loadingToast);
        // toast.error(`Failed to update cost center: ${error.toString()}`, {
        toast.error(`Failed to update cost center. Please try again later.`, {
          duration: 6000,
          style: {
            minWidth: "fit-content",
          },
        });
        console.error("Failed to update cost center:", error);
      });
  };

  // Handle cost center dropdown on-change evebt
  const handleCostCenterChange = (user, newCostCenterName) => {
    const newCostCenter = costCenterListData?.cost_centers?.find(
      (costCenter) => costCenter.name === newCostCenterName
    );

    const oldCostCenterId = usersCostCenters[user.id] || "";
    const oldCostCenter = costCenterListData?.cost_centers?.find(
      (costCenter) => costCenter._id === oldCostCenterId
    );

    const oldCostCenterName = oldCostCenter
      ? oldCostCenter.name
      : "Not assigned";

    setSelectedUser(user);
    setNewCostCenter({
      id: newCostCenter?._id || "",
      name: newCostCenterName,
    });
    setOldCostCenter({
      id: oldCostCenterId,
      name: oldCostCenterName,
    });

    setOpen(true);
  };

  const handleCostCenterModalConfirmationChange = () => {
    setUsersCostCenters((prev) => ({
      ...prev,
      [selectedUser?.id]: newCostCenter,
    }));
    setOpen(false);
    updateCostCenter(selectedUser.id, platformData?._id, newCostCenter.id)();
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Set columns
  useEffect(() => {
    if (updatedRows && orgPermissions) {
      setColumns([
        {
          field: "name",
          headerName: `Showing ${(currentPage - 1) * 25 + 1} - ${(currentPage - 1) * 25 + updatedRows?.length
            } of ${joinedUserListData?.total || 0} Users`,
          headerClassName:
            orgPermissions?.[0]?.userType === "Owner"
              ? "history-tab-totalCount-header"
              : "history-tab-totalCount-header-approver-view",
          headerAlign: "left",
          align: "left",
          width: orgPermissions?.[0]?.userType === "Owner" ? 270 : 350,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft:
                  orgPermissions?.[0]?.userType === "Owner" ? "0px" : "30px",
              }}
            >
              <AvatarRandomColor
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                color={params.row.color}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.name}</span>
                  }
                  placement="top-start"
                  arrow
                >
                  <span
                    className="table-history-userEmailJoined-cell"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {params.row.name}
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.email}</span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {params.row.email.length > emailTruncateLimit ? (
                    <div>
                      <span
                        className="table-cell-trucate-reason"
                        style={{ paddingLeft: "0", fontSize: "13px" }}
                      >
                        {params.row.email?.slice(0, emailTruncateLimit) + "..."}
                      </span>{" "}
                    </div>
                  ) : (
                    <span style={{ fontSize: "13px" }}>{params.row.email}</span>
                  )}
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          field: "JoinedDate",
          headerName: "Date of Joining",
          headerClassName: "date-joined-table-header",
          headerAlign: "center",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 100 : 240,
          valueGetter: (params) => {
            // Return the date as a Date object for proper sorting
            return new Date(params.row.date);
          },
          renderCell: (params) => (
            <span className="history-tab-joinedDate-cell ">
              {params.row.date}
            </span>
          ),
        },
        {
          field: "approvedBy",
          headerName: "Approved By",
          headerClassName: "orgName-column-header",
          headerAlign: "center",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 200 : 350,
          renderCell: (params) => (
            <Tooltip
              title={<p style={{ fontSize: "12px" }}>{params.row.changedBy}</p>}
            >
              <span className="table-cell-history">{params.row.changedBy}</span>
            </Tooltip>
          ),
        },
        //Cost Center field
        orgPermissions?.[0]?.userType === "Owner" && {
          field: "costCenter",
          headerName: "Cost Center",
          headerAlign: "center",
          align: "center",
          backgroundColor: "red",
          width: 180,
          renderCell: (params) => {
            if (
              !usersCostCenters ||
              isCostCenterListDataLoading ||
              !isCostCenterListDataSuccess
            ) {
              return <CircularProgress size={24} />;
            }
            const userCostCenterId = usersCostCenters[params.row.id] || "";
            const costCenter = costCenterListData?.cost_centers?.find(
              (option) => option._id === userCostCenterId
            );
            const userCostCenterName = costCenter
              ? costCenter.name
              : "Select option";

            return (
              <Tooltip title={userCostCenterName} placement="top">
                <TextField
                  sx={{
                    "& .MuiInputBase-input": { padding: "7.5px 14px" },
                  }}
                  id="costCenter"
                  select
                  value={userCostCenterName}
                  onChange={(e) =>
                    handleCostCenterChange(params.row, e.target.value)
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                  defaultValue=""
                >
                  {userCostCenterName === "Select option" && (
                    <MenuItem value="Select option" disabled>
                      Select option
                    </MenuItem>
                  )}
                  {costCenterListData?.cost_centers?.map((option) => (
                    <MenuItem key={option?._id} value={option?.name}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            );
          },
        },
        orgPermissions?.[0]?.userType === "Owner" && {
          field: "action",
          headerAlign: "center",
          headerName: "Actions",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 160 : 0,
          filterable: false,
          renderCell: (params) => (
            <ReusableActions
              params={params}
              action={
                params.row.status === "joined"
                  ? () =>
                    action(params.row.id, platformData?._id, orgId, userEmail)
                  : () => { }
              }
            />
          ),
        },
      ]);
    }
  }, [
    updatedRows,
    orgPermissions,
    usersCostCenters,
    costCenterListData,
    isCostCenterListDataSuccess,
  ]);

  return (
    <div>
      <ReusableDataGrid
        columns={columns}
        rows={updatedRows}
        orgName={orgName}
        viewType={"Joined History"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalRecords={joinedUserListData?.total || 0}
        checkboxSelection={orgPermissions?.[0]?.userType === "Owner"}
        setQuery={setQuery}
        query={query}
        isLoading={
          isJoinedUserListDataLoading ||
          // isRemoveRequestedUserListDataLoading ||
          isUpdateUserOrgStatusLoading ||
          isPlatformDataLoading ||
          isCostCenterListDataLoading
        }
        tabDescription={
          "Users in Joined have been successfully added to the organization. These users will have access to the organization"
        }
      />
      <ChangeCostCenterModal
        show={open}
        close={handleClose}
        selectedUser={selectedUser}
        newCostCenter={newCostCenter}
        oldCostCenter={oldCostCenter}
        handleConfirmChange={handleCostCenterModalConfirmationChange}
      />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default JoinedTab;
