import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";
import { EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW } from "utils/constants";
import { generateRandomColor } from "utils/generateRandomColor";

import AvatarRandomColor from "../ReusableDataGrid/AvatarRandomColor";
import ReusableActions from "../ReusableDataGrid/ReusableActions";
import ReusableDataGrid from "../ReusableDataGrid/ReusableDataGrid";

import "../TabStyles.css";

const RejectedTab = ({ userRejectedHistoryMockList, orgName }) => {
  const [columns, setColumns] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { orgId } = useParams();

  const { data: platformData, isFetching: isPlatformDataLoading } =
    useGetPlatformsQuery();

  const {
    data: rejectedUserListData,
    isFetching: isRejectedUserListDataLoading,
  } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Rejected",
      platformId: platformData?._id,
      page: currentPage,
      perPage: 25,
      email: query === "" ? undefined : query,
    },
    { skip: !platformData?._id }
  );

  // Set datagrid rows
  useEffect(() => {
    if (rejectedUserListData) {
      setUpdatedRows(rejectedUserListData?.users);
    }
  }, [rejectedUserListData]);

  // Mutation to update user status
  const [updateUserOrgStatus, { isLoading: isUpdateUserOrgStatusLoading }] =
    useUpdateUserOrgStatusMutation();

  // Logged in user's email
  const userEmail = useSelector((state) => state.auth.userAccount?.username);

  // Function to execute when button is clicked in action column of datagrid
  const action = (userId, platformId, orgId, userEmail) => {
    return (rejectReason, otherReason) => {
      updateUserOrgStatus({
        userId: userId,
        body: {
          platforms: {
            [platformId]: {
              organizations: {
                [orgId]: {
                  membership_status: "Not-Requested",
                  reason: rejectReason === "Other" ? otherReason : rejectReason,
                  changed_by: userEmail.toLowerCase(),
                },
              },
            },
          },
        },
        attribute: "membership_status",
      });
    };
  };

  useEffect(() => {
    if (updatedRows) {
      setColumns([
        {
          field: "name",
          headerName: `Showing ${(currentPage - 1) * 25 + 1} - ${(currentPage - 1) * 25 + updatedRows?.length
            } of ${rejectedUserListData?.total || 0} Users`,
          headerClassName: "history-tab-totalCount-header",
          headerAlign: "left",
          align: "left",
          width: 270,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <AvatarRandomColor
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                color={params.row.color}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.name}</span>
                  }
                  placement="top-start"
                  arrow
                >
                  <span
                    className="table-history-userEmailJoined-cell"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {params.row.name}
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.email}</span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {params.row.email.length >
                    EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW ? (
                    <div>
                      <span
                        className="table-cell-trucate-reason"
                        style={{ paddingLeft: "0", fontSize: "13px" }}
                      >
                        {params.row.email?.slice(
                          0,
                          EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW
                        ) + "..."}
                      </span>{" "}
                    </div>
                  ) : (
                    <span style={{ fontSize: "13px" }}>{params.row.email}</span>
                  )}
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          field: "DOJ",
          headerName: "Date of Rejection",
          headerClassName: "date-rejected-table-header",
          headerAlign: "center",
          align: "center",
          width: 140,
          valueGetter: (params) => {
            // Return the date as a Date object for proper sorting
            return new Date(params.row.date);
          },
          renderCell: (params) => (
            <span className="table-history-DateRejected-cell">
              {params.row.date}
            </span>
          ),
        },
        {
          field: "rejectedBy",
          headerName: "Rejected By",
          headerClassName: "orgName-column-header",
          headerAlign: "center",
          align: "left",
          width: 280,
          renderCell: (params) => (
            <Tooltip
              title={<p style={{ fontSize: "12px" }}>{params.row.changedBy}</p>}
            >
              <span className="table-cell-history">{params.row.changedBy}</span>
            </Tooltip>
          ),
        },
        {
          field: "action",
          headerAlign: "center",
          headerName: "Actions",
          align: "center",
          width: 220,
          filterable: false,
          renderCell: (params) => (
            <ReusableActions
              params={params}
              action={
                params.row.status === "rejected"
                  ? () =>
                    action(params.row.id, platformData?._id, orgId, userEmail)
                  : () => { }
              }
            />
          ),
        },
      ]);
    }
  }, [updatedRows]);

  return (
    <div>
      <ReusableDataGrid
        columns={columns}
        rows={updatedRows}
        orgName={orgName || "Deloitte UK Innersource"}
        viewType={"Rejected History"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setQuery={setQuery}
        query={query}
        checkboxSelection={true}
        totalRecords={rejectedUserListData?.total || 0}
        isLoading={
          isRejectedUserListDataLoading ||
          isPlatformDataLoading ||
          isUpdateUserOrgStatusLoading
        }
        tabDescription={
          "Users in Rejected have had their access request to the organization denied. These users will not be able to re-request access to the organization until their profile has been reset"
        }
      />
    </div>
  );
};

export default RejectedTab;
